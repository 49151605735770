<template>
  <div id="company-overview-controls">
    <el-button
      icon="el-icon-user-solid"
      size="medium"
      @click="$router.push({ name: 'PortalInvite' })"
    >
      Send Portal Invite
    </el-button>
    <el-dropdown
      trigger="click"
      @command="handleDropdownSelect"
    >
      <el-button
        size="medium"
        icon="el-icon-more"
      >
        <i class="el-icon-arrow-down el-icon--right" />
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="spreadsheet">
          Spreadsheet View
        </el-dropdown-item>
        <el-dropdown-item command="map">
          Map View
        </el-dropdown-item>
        <el-dropdown-item
          v-if="zoho.customerId"
          command="zoho"
        >
          Zoho Books
        </el-dropdown-item>
        <el-dropdown-item
          v-if="company.jira_mapping_url"
          command="jira"
        >
          JIRA
        </el-dropdown-item>
        <el-dropdown-item command="reporting">
          Dashboard Reporting
        </el-dropdown-item>
        <el-dropdown-item
          v-if="['active', 'onboarding'].includes(company.status)"
          command="cancel"
          divided
        >
          Cancel All Subscriptions
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: 'CompanyOverviewControls',
  props: {
    company: {
      type: Object,
      required: true
    },
    zoho: {
      type: Object,
      required: true
    }
  },
  methods: {
    /**
     * Handler for when an item from the dropdown is clicked.
     *
     * @param {String} command
     */
    handleDropdownSelect (command) {
      switch (command) {
        case 'spreadsheet':
          this.$router.push({ name: 'PropertySpreadsheet' })
          break
        case 'map':
          this.$router.push({ name: 'PropertyMap' })
          break
        case 'zoho':
          window.open(`https://books.zoho.com/app#/contacts/${this.zoho.customerId}`)
          break
        case 'jira':
          window.open(this.company.jira_mapping_url)
          break
        case 'reporting':
          window.open(`${process.env.VUE_APP_PORTAL_URL}companies/${this.$route.params.cid}/performance-report`)
          break
        case 'cancel':
          this.$emit('cancel-company', true)
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dropdown {
  margin-left: 10px;
}
</style>
