<template>
  <div>
    <el-row
      type="flex"
      justify="space-between"
      align="center"
    >
      <h1 class="overview-title">
        {{ company.human_name }}
      </h1>
      <company-controls
        :company="company"
        :zoho="zoho"
        @cancel-company="showCompanyCancelDialog = true"
      />
    </el-row>

    <el-row>
      <el-tabs
        ref="tabs"
        :value="$route.name"
        @tab-click="$route.name !== $event.name ? $router.push({ name: $event.name }) : null"
      >
        <el-tab-pane
          v-for="tab in tabs"
          :key="tab.route"
          :label="tab.label"
          :name="tab.route"
        />
        <keep-alive>
          <router-view
            ref="activeTab"
            :key="$route.fullPath"
            :company="company"
            :zoho="zoho"
            @refresh="fetchZohoFields"
            @navigation-cancel="handleNavigationCancel"
          />
        </keep-alive>
      </el-tabs>
    </el-row>

    <cancel-item-dialog
      :item="company"
      :show="showCompanyCancelDialog"
      :loading="cancelCompanyLoading"
      :title="`Cancel Subscriptions for ${company.human_name}?`"
      warning="All properties connected
        to this company will inherit this end date and reason for cancellation
        if they don't yet have either of these values set."
      @close="showCompanyCancelDialog = false"
      @submit="cancelCompany"
    />
    <el-dialog
      class="cancel-error-dialog"
      :visible="showCompanyCancelError"
      title="Subscription Cancellation Failed"
      @close="showCompanyCancelError = false"
    >
      The following properties contain subscriptions that cannot be cancelled.
      Please ensure that the specified cancellation date is <strong>AFTER</strong> any subscriptions' start and end dates,
      and that the subscriptions are in a valid state (active, onboarding, offboarding).
      <ul>
        <li v-for="property in failedProperties" :key="property">
          {{ property }}
        </li>
      </ul>
      <span slot="footer">
        <el-button type="primary" @click="showCompanyCancelError = false">
          OK
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CompanyOverviewControls from './_components/CompanyOverviewControls'
import CancelItemDialog from '@/components/dialogs/CancelItemDialog'
import RooofAPI from '@/services/api/rooof'
import InvoicesAPI from '@/services/api/invoices'

export default {
  name: 'CompanyOverview',
  components: {
    'company-controls': CompanyOverviewControls,
    'cancel-item-dialog': CancelItemDialog
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    loadingCompany: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      showCompanyCancelDialog: false,
      cancelCompanyLoading: false,
      showCompanyCancelError: false,
      failedProperties: [],
      tabs: [
        { label: 'Properties', route: 'Properties' },
        { label: 'Reporting Groups', route: 'ReportingGroups' },
        { label: 'Invoicing Groups', route: 'InvoicingGroups' },
        { label: 'Company Info', route: 'CompanyInfo' },
        { label: 'Company Notes', route: 'CompanyNotes' }
      ],
      zoho: {
        customerId: '',
        recurringInvoiceId: ''
      }
    }
  },
  created () {
    this.fetchZohoFields()
  },
  methods: {
    refreshPropertyList () {
      if (this.$route.name === 'Properties') {
        const params = { page: 1, page_size: 25 }
        this.$refs['activeTab'].fetchData(params)
      }
    },
    /**
     * Submit request to cancel this company.
     */
    async cancelCompany (companyId, formData) {
      try {
        this.cancelCompanyLoading = true
        await RooofAPI.companies.cancel(companyId, formData)
      } catch (err) {
        const details = err.response ? err.response.data : null
        if (details && details.failed_property_cancellations) {
          this.failedProperties = details.failed_property_cancellations
          this.showCompanyCancelError = true
        } else {
          this.$rfAlert.error(this, err.toString(), details)
        }
      } finally {
        this.cancelCompanyLoading = false
        this.$emit('refresh')
        this.refreshPropertyList()
        this.showCompanyCancelDialog = false
      }
    },
    /**
     * Retrieves the top level invoicing group's zoho fields from the API.
     */
    async fetchZohoFields () {
      try {
        const params = { company_id: this.company.id }
        const response = await InvoicesAPI.groups.list(params)
        const group = response.length ? response[0] : null
        if (group) {
          this.zoho.customerId = group.zoho_customer_id
          this.zoho.recurringInvoiceId = group.zoho_recurring_invoice_id
        }
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Handles when a navigation is cancelled via a route guard.
     * Ensures that the selected tab still matches the route name.
     */
    handleNavigationCancel () {
      this.$refs.tabs.currentName = this.$route.name
    }
  }
}
</script>

<style lang="scss" scoped>
.overview-title {
  margin-top: 0;
  font-size: 2rem;
}
.cancel-error-dialog {
  ::v-deep .el-dialog__body {
    padding: 10px 20px;
    word-break: break-word;
    line-height: 1.5em;
  }
}
</style>
