<template>
  <el-dialog
    :visible="show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :title="title"
    width="450px"
    @close="cancel"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="150px"
    >
      <el-form-item
        label="Start Date"
      >
        <el-date-picker
          v-model="item.start_date"
          type="date"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          placeholder="Required"
          disabled
        />
      </el-form-item>
      <el-form-item
        label="End Date"
        prop="end_date"
      >
        <el-date-picker
          v-model="form.cancellation_date"
          :picker-options="pickerOptions"
          type="date"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          placeholder="Choose date..."
        />
      </el-form-item>
      <el-form-item
        label="Reason for Cancel"
        prop="reason_for_cancel"
      >
        <el-select v-model="form.reason_for_cancel">
          <el-option
            v-for="reason in reasonForCancel"
            :key="reason.value"
            :label="reason.label"
            :value="reason.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.reason_for_cancel === 'other'"
        label="Please Specify"
        prop="reason_for_cancel_other"
      >
        <el-input v-model="form.reason_for_cancel_other" />
      </el-form-item>
    </el-form>

    <div v-if="warning" class="info">
      <span class="warning">WARNING</span>:
      {{ warning }}
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">
        Cancel
      </el-button>
      <el-button
        :loading="loading"
        type="primary"
        @click="validate"
      >
        Submit
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { constants } from '@/utils/constants'
export default {
  props: {
    title: {
      type: String,
      default: 'Cancel Item'
    },
    item: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    },
    warning: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rules: {
        cancellation_date: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ],
        reason_for_cancel: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ],
        reason_for_cancel_other: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ]
      },
      pickerOptions: {
        disabledDate: (date) => {
          return this.$moment(date) <= this.$moment(this.item.start_date)
        }
      },
      form: {
        cancellation_date: '',
        reason_for_cancel: '',
        reason_for_cancel_other: ''
      }
    }
  },
  created () {
    this.constants = constants
    const toRemove = ['subscription-change', 'switching-to-manual']
    this.reasonForCancel = this.constants.reasonForCancel.filter((el) =>
      !toRemove.includes(el.value)
    )
  },
  methods: {
    validate () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$emit('submit', this.item.id, this.form)
        }
      })
    },
    cancel () {
      this.$emit('close')
      this.reset()
    },
    reset () {
      this.$refs['form'].resetFields()
      this.$refs['form'].clearValidate()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item__content > div {
    width: 100%;
}
.info {
  word-break: break-word;

  .warning {
    color: #f56c6c;
    font-weight: bold;
  }
}
</style>
